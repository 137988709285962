import React, { useCallback, useState } from "react";
import classNames from "classnames";
import { useIntl } from "react-intl";

import ButtonLink from "components/ButtonLink";
import FormattedMessage from "helpers/formattedMessage";
import { Link, useLocation } from "react-router-dom";
import routeHelper from "i18n/routeHelper";
import useTopicSummaries from "hooks/useTopicSummaries";

import useWebAppLink from "hooks/useWebAppLink/useWebAppLink";
import BranchLink from "../BranchLink";

import styles from "./PageHeaderBar.module.scss";
import Button from "../Button";
import ImageNextGen from "../ImageNextGen";

import Images from "./images";

import {
  goalHeaderJoinAppClick,
  goalJoinCTA,
  goalLoginCTA,
  goalNavItemClick
} from "../../helpers/goalTracking";

import imageMenuClose from "./assets/menu_close.svg";

import "./critical.css";

const { imageMenu, imageLogo } = Images;

interface IProps {
  className?: string;
  defaultMargin?: boolean;
}

const PageHeaderBar = ({ className, defaultMargin = true }: IProps) => {
  const intl = useIntl();
  const { pathname } = useLocation();
  const localeRouteHelper = routeHelper[intl.locale];
  const { data } = useTopicSummaries({ locale: intl.locale });
  const [displayMenu, setDisplayMenu] = useState(false);

  const handleJoin = useCallback(() => {
    goalJoinCTA();
  }, []);

  const handleMenuShow = useCallback(() => {
    document.body.style.overflow = "hidden";
    setDisplayMenu(true);
  }, []);

  const handleMenuHide = useCallback((item) => {
    console.log("handleMenuHide", item)
    goalNavItemClick(item);
    document.body.style.overflow = "auto";
    setDisplayMenu(false);
  }, []);

  return (
    <>
      <div
        className={classNames(
          "critical-pageHeader--wrapper",
          styles.wrapper,
          className
        )}
        data-testid="page-header-bar"
      >
        <div className={styles.menu}>
          <div onClick={handleMenuShow} className={styles.menuOpenIcon}>
            <ImageNextGen
              manifest={imageMenu}
              src={imageMenu.all.webp["1x"]}
              alt={intl.formatMessage({
                id: "generic.open",
                defaultMessage: "Open",
                description: "Alt text for Open the side menu on mobile"
              })}
              className={styles.menuOpenIconImage}
            />
          </div>

          <nav
            className={classNames({
              [styles.menuOpen]: displayMenu
            })}
          >
            <div onClick={() => handleMenuHide("mobile-close-button")} className={styles.menuCloseIcon}>
              <img
                src={imageMenuClose}
                alt={intl.formatMessage({
                  id: "generic.close",
                  defaultMessage: "Close",
                  description: "Alt text for Close the side menu on mobile"
                })}
              />
            </div>
            {/* <ul>{menuItems()}</ul> */}
            <div className={classNames(styles.cta, styles.menuOpenCta)}>
              <BranchLink onClick={goalHeaderJoinAppClick}>
                <Button
                  className={classNames(
                    styles.button,
                    styles.menuDownloadAppButton
                  )}
                >
                  <FormattedMessage
                    description="Download app button in the mobile menu"
                    defaultMessage="Download App"
                    id="components.pageheaderbar.download_app"
                  />
                </Button>
              </BranchLink>
            </div>

            <ul>
              <li>
                <Link to={localeRouteHelper.about()}>
                  <FormattedMessage
                      defaultMessage="About us"
                      description="Navigation: About us"
                      id="generic.navigation.aboutus"
                  />
                </Link>
              </li>
              <li>
                <Link to={localeRouteHelper.topicBlogIndex()}>
                  <FormattedMessage
                    defaultMessage="Blog"
                    description="Navigation: Blog"
                    id="generic.navigation.blogindex"
                  />
                </Link>
              </li>
              <li>
                <Link to={localeRouteHelper.community()}>
                  <FormattedMessage
                    defaultMessage="Community"
                    description="Navigation: Community"
                    id="generic.navigation.community"
                  />
                </Link>
              </li>
              {intl.locale === "en" && (
                <li>
                  <a
                    href="https://invisible-mothers.peanut-app.io"
                    onClick={() => handleMenuHide("invisible-mothers")}
                  >
                    Invisible Mothers
                  </a>
                </li>
              )}
            </ul>
            <ul className={styles.bottomMobileNav}>
              <li>
                <Link to={localeRouteHelper.babyNames()}>
                  <FormattedMessage
                    defaultMessage="Baby Names"
                    description="Navigation: Baby Names"
                    id="generic.navigation.babynames"
                  />
                </Link>
              </li>
              <li>
                <Link to={localeRouteHelper.medicalReview()}>
                  <FormattedMessage
                    defaultMessage="Medical Review"
                    description="Navigation: Medical Review"
                    id="generic.navigation.medicalreview"
                  />
                </Link>
              </li>
            </ul>
          </nav>
        </div>

        <div className={styles.logo}>
          <Link to={localeRouteHelper.home()}>
            <ImageNextGen
              manifest={imageLogo}
              src={imageLogo.mobile.svg["1x"]}
              alt={intl.formatMessage({
                id: "generic.peanut_app",
                defaultMessage: "Peanut App",
                description: "Alt text for misc. screen shots"
              })}
              className={styles.menuCloseIconImage}
            />
          </Link>
        </div>
        <div className={classNames(styles.cta)}>
          <ButtonLink
            to={localeRouteHelper.download()}
            onClick={handleJoin}
            className={styles.button}
          >
            <FormattedMessage
              description="Join button in the page header navigation"
              defaultMessage="Join"
              id="components.pageheaderbar.join"
            />
          </ButtonLink>
        </div>
      </div>
      {defaultMargin && <div className={styles.defaultMargin} />}
    </>
  );
};

export default PageHeaderBar;
